import React from "react"
import { graphql } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Navbar from "../components/navbar/navbar"
import NavbarMobile from "../components/navbar/navbarMobile"
import Footer from "../components/footer/footer"
import FooterMobile from "../components/footer/footerMobile"

import Sobre from "../components/doublepage/sobre"
import SobreMobile from "../components/doublepage/sobreMobile"

const AboutPage = ({ data }) => (
  <Layout>
    <SEO title="Sobre | Carreira Dental Clinic" />
    {useBreakpoint().mobile ? (
      <>
        <NavbarMobile />
        <SobreMobile data={data.sobreJson} />
        <FooterMobile data={data.globalJson.footer} />
      </>
    ) : (
      <>
        <Navbar />
        <Sobre data={data.sobreJson} />
        <Footer data={data.globalJson.footer} />
      </>
    )}
  </Layout>
)

export default AboutPage

export const Json = graphql`
  query about {
    sobreJson {
      introE
      introCorpo
      introC
      banner {
        img {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        title
        subtitle
        text
      }
      bannerPat {
        bg {
          publicURL
        }
        img {
          publicURL
        }
      }
      bannerManager {
        img {
          childImageSharp {
            fluid(quality: 100, maxWidth: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      equipa {
        banner {
          img {
            childImageSharp {
              fluid(quality: 100, maxWidth: 5000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        team {
          img {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          nome
          title
          specialties
          omd
        }
      }
      clinicasSlider {
        aveiro {
          img {
            childImageSharp {
              fluid(quality: 100, maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          alt
        }
        agueda {
          img {
            childImageSharp {
              fluid(quality: 100, maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          alt
        }
        porto {
          img {
            childImageSharp {
              fluid(quality: 100, maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          alt
        }
        viseu {
          img {
            childImageSharp {
              fluid(quality: 100, maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          alt
        }
        oliazemeis {
          img {
            childImageSharp {
              fluid(quality: 100, maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          alt
        }
      }
    }
    globalJson {
      footer {
        newsletter {
          subscreva
          btnTxt
        }
        aveiro {
          title
          morada
          contacto
        }
        agueda {
          title
          morada
          contacto
        }
        porto {
          title
          morada
          contacto
        }
        viseu {
          title
          morada
          contacto
        }
        oliveira {
          title
          morada
          contacto
        }
        email
        schedule
        bg {
          publicURL
        }
      }
    }
  }
`
