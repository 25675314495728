import React from "react"
import Img from "gatsby-image"

import styled from "styled-components"
import font from "../../fonts/fonts.module.scss"

const Banner = ({ data }) => {

  return (
    <StyledBanner>
      {data.map((itemDoctor, index) => (
        <div key={index} className={`bannerContent ${index % 2 === 0 ? "even" : "odd"}`}>
          <div className="bannerImg">
            <Img className="img" fluid={itemDoctor.img.childImageSharp.fluid} imgStyle={{ objectFit: "contain" }} />
          </div>
          <div className="sobre-banner-text">
            <span className={font.rB + " sobre-BTitle"}>{itemDoctor.title}</span><br />
            <span className={font.rMI + " sobre-BSub"}>{itemDoctor.subtitle}</span><br /><br /><br />
            <p className={font.rM + " sobre-BContent"} dangerouslySetInnerHTML={{ __html: itemDoctor.text }} />
          </div>
        </div>
      ))}
    </StyledBanner>
  )
}

export default Banner

const StyledBanner = styled.div`
  margin-top: 6vw;
  .bannerImg{
    width: 85%;
    max-width: 600px;
    max-height: 600px;
    margin: auto;
    img{
      max-height: 600px;
    }
  }
  .sobre-banner-text{
    padding: 40px;
  }
  .sobre-BTitle{
    font-size: calc(20px + 12 * (100vw - 320px) / 448);
    @media only screen and (min-width: 769px){
      font-size: 32px;
    }
    line-height: 1.2em;
    color: #b7a99a;
    text-align: center;
  }
  .sobre-BSub{
    color: #231f20;
    font-size: calc(18px + 8 * (100vw - 320px) / 448);
    @media only screen and (min-width: 769px){
      font-size: 26px;
    }
    line-height: 1.1em;
    text-align: center;
  }
  .sobre-BContent{
    font-size: calc(14px + 6 * (100vw - 320px) / 448);
    @media only screen and (min-width: 769px){
      font-size: 20px;
    }
    line-height: 1.6em;
    letter-spacing: 0.1em;
    color: #707070;
  }
`