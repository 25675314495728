import React from "react"

import styled from "styled-components"
import font from "../../fonts/fonts.module.scss"

import Img from '../../images/sobre/sobre-banner.png'

const Intro = ({ data }) => {
  
  return(
    <StyledIntro img={Img}>
      <div className="img" />
      <div className='container'>
        <p className={font.rSB + " title"}>
          <span className="smallUnderline"></span>
          SOBRE 
        </p>
        <div className={font.rM + " sobre-intro"}>
          {data[1].length>1 ? <p>{data[0]}<br/><br/>{data[1]}</p> : <p>{data}</p>}
        </div>
      </div>
    </StyledIntro>
  )
}

export default Intro

const StyledIntro = styled.div`
  position: relative;
  .img{
    background-image: url(${props => (props.img && props.img)});
    background-size: cover;
    background-position: center;
    background-repeat:no-repeat;
    width: 100%;
    height: ${props => (props.img ? '50vh' : 'unset')}
  }
  .container{
    padding: 40px 40px 80px;
    .title{
      color: #231f20;
      font-size: calc(20px + 10 * (100vw - 320px) / 448);
      @media only screen and (min-width: 769px){
        font-size: 30px;
      }
      line-height: 1.2em;
      letter-spacing: 0.1em;
      position: relative;
      margin: 0 0 2em;
    }
    .smallUnderline{
      width: 2em;
      height: 0.25em;
      background-color: #b7a99a;
      position: absolute;
      bottom: -0.4em;
      left: 0;
    }
    .sobre-intro{
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 auto;
      font-size: calc(14px + 5 * (100vw - 320px) / 448);
      @media only screen and (min-width: 769px){
        font-size: 20px;
      }
      line-height: 1.6em;
      letter-spacing: 0.1em;
      color: #808080;
      height: fit-content;
      p{
        height: fit-content;
      }
    }
  } 
`