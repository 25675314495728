import React from "react"
import Img from "gatsby-image"

import styled from "styled-components"
import font from "../../fonts/fonts.module.scss"

const BannerManager = ({ data }) => {
  
  return(
    <StyledBanner>
      <div className="contentTxt">
        <p className={font.rB + " title"}>VASCO AFONSO</p>
        <p className={font.rMI + " subtitle"}>General Manager</p>
        <p className={font.rM + " text"}>
          Vasco Afonso possui um percurso de 14 anos na área da medicina dentária.<br/><br/> 
          Em Portugal, desempenhou o cargo de supervisor e de diretor nacional no Straumann Group - líder mundial em implantologia. Nos últimos anos, foi diretor comercial do mesmo grupo em Espanha, no qual dirigiu marcas como Neodent e Medentika.<br/><br/>
          Atualmente é o general manager da marca Carreira Dental Clinic.
        </p>
      </div>
      <div className="contentImg">
        <Img className="img" fluid={data.img.childImageSharp.fluid} imgStyle={{ objectFit: "contain" }}/>
      </div>
    </StyledBanner>
  )
}

export default BannerManager

const StyledBanner = styled.div`
  display: flex;
  margin-right: calc(0px + 143 * (100vw - 769px) / 1151);
  @media only screen and (min-width: 1920px){
    margin-right: 143px;
  }
  margin-top: 6vw;
  align-items: center;
  .contentTxt{
    width: 60%;
    padding: calc(20px + 68 * (100vw - 769px) / 1151) calc(20px + 140 * (100vw - 769px) / 1151);
    @media only screen and (min-width: 1920px){
      padding: 160px;
    }
    background-color: #eeeae6;
    height: fit-content;
  }
  .title{
    color: #b7a99a;
    font-size: calc(20px + 20 * (100vw - 769px) / 1151);
    @media only screen and (min-width: 1920px){
      font-size: 40px;
    }
    line-height: 1.1em;
    letter-spacing: 0.15em;
  }
  .subtitle{
    color: #231f20;
    letter-spacing: 0.15em;
    font-size: calc(17px + 13 * (100vw - 769px) / 1151);
    margin-bottom: calc(18px + 32 * (100vw - 769px) / 1151);
    @media only screen and (min-width: 1920px){
      font-size: 30px;
      margin-bottom: 50px;
    }
  }
  .text{
    font-size: calc(14px + 8 * (100vw - 769px) / 1151);
    @media only screen and (min-width: 1920px){
      font-size: 22px;
    }
    line-height: 1.5em;
    letter-spacing: 0.1em;
    color: #808080;
  }
  .contentImg{
    width: 40%;

    .img {
      width: 100%;
      height: 100%;
    }
  }
`