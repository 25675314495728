import React from "react"
import styled from "styled-components"
import TeamCard from "../cards/teamCard"
import { deletedTeamMembers } from "../../data/vars"

const Team = ({ data }) => {
  return (
    <StyledTeamList>
      {data.map((data, i) => {
        return (
          deletedTeamMembers.indexOf(data.nome) < 0 && (
            <TeamCard data={data} key={"TeamMember" + i} />
          )
        )
      })}
    </StyledTeamList>
  )
}

export default Team

const StyledTeamList = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  @media only screen and (max-width: 1600px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media only screen and (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media only screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media only screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
  grid-column-gap: 2vw;
  grid-row-gap: 2vw;
  width: 85%;
  margin: 8vw auto;
`
