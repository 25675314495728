import React, { useRef } from "react"

import styled from "styled-components"
import font from "../../fonts/fonts.module.scss"
import { Button } from "../buttons"

import videoSrc from "../../videos/adriano_e_vasco.mp4"
import playBtnImg from "../../images/homepage/btnPlay.svg"

const BannerPat = ({ data }) => {
  const video = useRef(null)
  const playBtn = useRef(null)

  function play() {
    if (video.current.paused) {
      video.current.play()
      playBtn.current.style.opacity = "0"
    } else {
      video.current.pause()
      playBtn.current.style.opacity = "1"
    }
  }
  function ended() {
    playBtn.current.style.opacity = "1"
  }

  return (
    <StyledBanner bg={data.bg}>
      <div className="contentImg">
        <div className="photo">
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <video
            playsInline
            crossOrigin="anonymous"
            ref={video}
            poster={data.img.publicURL}
            onEnded={ended}
          >
            <source src={videoSrc} type="video/mp4" />
          </video>
          <Button
            styleless
            onClick={play}
            className="playVidBtn"
            style={{ opacity: "1" }}
            ref={playBtn}
          >
            <img src={playBtnImg} alt="Play" id="btnPlay" />
          </Button>
        </div>
      </div>
      <div className={font.rM + " contentTxt"}>
        A mudança é inevitável quando nos comprometemos com o futuro.
      </div>
    </StyledBanner>
  )
}

export default BannerPat

const StyledBanner = styled.div`
  position: relative;
  .contentImg {
    width: 100%;
    background-image: url(${props => props.bg && props.bg.publicURL});
    background-size: cover;
    padding-top: 8vw;
    .photo {
      width: 60%;
      margin: auto;
      line-height: 0;
      position: relative;
      overflow: hidden;
      :hover {
        .playVidBtn {
          opacity: 0.5;
        }
      }
      video {
        width: 100%;
        object-fit: cover;
      }
      .playVidBtn {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .contentTxt {
    width: 60%;
    margin: auto;
    background-color: #b7a99a;
    padding: 3vw 5vw;
    font-size: calc(13px + 9 * (100vw - 769px) / 1151);
    @media only screen and (min-width: 1920px) {
      font-size: 22px;
    }
    line-height: 1.6em;
    color: #ffffff;
    letter-spacing: 0.15em;
  }
`
