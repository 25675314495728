import React, { useRef } from "react"

import styled from "styled-components"
import font from "../../fonts/fonts.module.scss"
import { Button } from "../buttons"

import videoSrc from "../../videos/adriano_e_vasco.mp4"
import playBtnImg from "../../images/homepage/btnPlay.svg"

const BannerPat = ({ data }) => {
  const video = useRef(null)
  const playBtn = useRef(null)

  function play() {
    if (video.current.paused) {
      video.current.play()
      playBtn.current.style.opacity = "0"
    } else {
      video.current.pause()
      playBtn.current.style.opacity = "1"
    }
  }
  function ended() {
    playBtn.current.style.opacity = "1"
  }

  return (
    <StyledBanner>
      <div className="contentImg">
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video
          playsInline
          crossOrigin="anonymous"
          ref={video}
          poster={data.img.publicURL}
          onEnded={ended}
        >
          <source src={videoSrc} type="video/mp4" />
        </video>
        <Button
          styleless
          onClick={play}
          className="playVidBtn"
          style={{ opacity: "1" }}
          ref={playBtn}
        >
          <img src={playBtnImg} alt="Play" id="btnPlay" />
        </Button>
      </div>
      <div className={font.rM + " contentTxt"}>
        Esta é uma parceria que espelha um sucesso notável pela busca da
        excelência. Com a colaboração de Vasco Afonso, a marca Carreira Dental
        Clinic tornou-se mais forte e contemporânea. A participação frequente
        nos mais variados congressos nacionais é realizada, sempre que possível,
        em equipa, tendo como finalidade prestar o melhor e o mais rigoroso
        serviço a cada paciente.
      </div>
    </StyledBanner>
  )
}

export default BannerPat

const StyledBanner = styled.div`
  position: relative;
  .contentImg {
    width: 100%;
    padding-top: 8vw;
    line-height: 0;
    position: relative;
    overflow: hidden;
    video {
      width: 100%;
      object-fit: cover;
    }
    .playVidBtn {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .contentTxt {
    background-color: #b7a99a;
    padding: calc(28px + 12 * (100vw - 320px) / 448);
    font-size: calc(13px + 6 * (100vw - 320px) / 448);
    @media only screen and (min-width: 769px) {
      padding: 40px;
      font-size: 20px;
    }
    line-height: 1.6em;
    letter-spacing: 0.1em;
    color: #ffffff;
  }
`
