import React from "react"
import Slider from "react-slick"
import Img from "gatsby-image"

import styled from "styled-components"
import font from "../../fonts/fonts.module.scss"

const CCSlider = ({ data, city, oliazemeis }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    arrows: false
  };

  return(
    <StyledSlider>
      <div className="slider">
        <div className={oliazemeis ? font.rSB + " cidadeTop cidadeOliazemeis" : font.rSB + " cidadeTop"}>{oliazemeis ? "OLIVEIRA DE AZEMÉIS" : city}</div>
        <Slider {...settings}>
          {data.map((data, i)=>(
            <div className="slide" key={"clinicaSlide" + i}>
              <div className="clinica">
                <Img fluid={data.img.childImageSharp.fluid} style={{ width: `100%`, height: "auto" }} imgStyle={{ objectFit: "cover" }} alt={data.alt && data.alt}/>
              </div>
            </div>
          ))}
        </Slider>
        <div className={font.rSB + " cidadeBottom"}>{city}</div>
      </div>
    </StyledSlider>
  )
}

export default CCSlider

const StyledSlider = styled.div`
  position: relative;
  padding: 60px 0 calc(60px + 60 * (100vw - 320px) / 448);
  .slider{
    position: relative;
    width: 100%;
    margin: auto;
    .slick-track{
      display: flex;
    }
    .slick-list{
      overflow: hidden;
    }
    .slide{
      height: 100%;
      width: 100%;
      .clinica{
        width: 100%;
        position: relative;
      }
    }
  }
  .cidadeTop{
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    font-size: calc(12px + 4 * (100vw - 320px) / 448);
    letter-spacing: 0.2em;
    color: #fff;
    background-color: #b7a99a;
    padding: calc(7px + 3 * (100vw - 320px) / 448) calc(16px + 4 * (100vw - 320px) / 448);
  }
  .cidadeOliazemeis{
    font-size: calc(9px + 4 * (100vw - 320px) / 448) !important;
  }
  .cidadeBottom{
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    transform: translateY(55%);
    -webkit-text-stroke: 2px #b7a99a;
    @media only screen and (max-width: 450px){
      -webkit-text-stroke: 1px #b7a99a;
    }
    color: transparent;
    z-index: -1;
    font-size: calc(50px + 40 * (100vw - 320px) / 448);
    letter-spacing: 0.2em;
  }
`