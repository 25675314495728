import React from "react"
import Img from "gatsby-image"
import Slider from "react-slick"

import styled from "styled-components"

const TeamBanner = ({ data }) => {
  console.log(data)
  const settings = {
    dots: false,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 3000,
  }
  return(
    <StyledBanner>
      <div className="bannerContent">
        <Slider {...settings}>
          {data.map((data, i) => (
            <div className="bannerImg" key={"equipa-slide" + i}>
              <Img
                fluid={data.img.childImageSharp.fluid}
                imgStyle={{ objectFit: "cover" }}
                alt={data.alt && data.alt}
                className="img"
              />
            </div>
          ))}
        </Slider>
      </div>
    </StyledBanner>
  )
}

export default TeamBanner

const StyledBanner = styled.div`
  position: relative;
  margin: 10vw 0 0;
  width: 100%;
  padding-bottom: 7vw;
  .bannerContent {
    position: relative;
    background-color: black;
    width: 100%;
    .bannerImg {
      position: relative;

      .img {
        width: 100%; 
        height: 100%;
      }

    }
  }
  
  .slick-slider {
    line-height: 0;
  }
  .slick-track {
    display: flex;
  }
  .slick-list {
    overflow: hidden;
  }
`