import React from "react"
import Slider from "react-slick"
import Img from "gatsby-image"

import styled from "styled-components"
import font from "../../fonts/fonts.module.scss"
import { Button } from "../../components/buttons"

import seta from "../../images/homepage/seta.svg"

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <Button
      className={className}
      style={{ ...style }}
      onClick={onClick}
      noHover
      regular
    >
      <img src={seta} alt="Seta"/>
    </Button>
  );
}
function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <Button
      className={className}
      style={{ ...style }}
      onClick={onClick}
      noHover
      regular
    >
      <img src={seta} alt="Seta"/>
    </Button>
  );
}

const CCSlider = ({ data, city, oliazemeis }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    arrows: true,

    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
  };

  return(
    <StyledSlider>
      <div className="slider">
        <div className={font.rSB + " cidadeTop"}>{oliazemeis ? "OLIVEIRA DE AZEMÉIS" : city}</div>
        <Slider {...settings}>
          {data.map((data, i)=>(
            <div className="slide" key={"clinicaSlide" + i}>
              <div className="clinica">
                <Img fluid={data.img.childImageSharp.fluid} style={{ width: `100%`, height: "auto" }} imgStyle={{ objectFit: "cover" }} alt={data.alt && data.alt}/>
              </div>
            </div>
          ))}
        </Slider>
        <div className={oliazemeis ? font.rSB + " cidadeBottom cidadeOliazemeis" : font.rSB + " cidadeBottom"}>{city}</div>
      </div>
    </StyledSlider>
  )
}

export default CCSlider

const StyledSlider = styled.div`
.slick-prev:before{
  content: '';
}
.slick-next:before{
  content: '';
}
  position: relative;
  margin: 8vw 0 calc(123px + 127 * (100vw - 769px) / 1151);
  @media only screen and (min-width: 2560px){
    margin: 120px 0 350px;
  }
  .slider{
    position: relative;
    width: 60%;
    margin: auto;
    .slick-track{
      display: flex;
    }
    .slick-list{
      overflow: hidden;
    }
    .slideTitle{
      color: #231f20;
      font-size: 20px;
      text-align: center;
      letter-spacing: 4px;
      line-height: 24px;
      text-transform: uppercase;
      z-index: 2;
      width: 100%;
      padding: 10px;
      text-align: center;
    }
    .slide{
      height: 100%;
      width: 100%;
      .clinica{
        width: 100%;
        position: relative;
      }
    }
  }
  .slick-prev{
    width: calc(36px + 24 * (100vw - 769px) / 1151);
    height: calc(36px + 24 * (100vw - 769px) / 1151);
    @media only screen and (min-width: 1920px){
      width: 60px;
      height: 60px;
    }
    background-color: #b7a99a;
    color: #ffffff;
    z-index: 1;

    -webkit-touch-callout:none;
    -webkit-tap-highlight-color:rgba(0,0,0,0);
    user-select: none;
    cursor: pointer;
    position: absolute;
    bottom: 50%;
    left: calc(-70px + -80 * (100vw - 769px) / 1151);
    transform: translate(-50%, 50%);
    img{
      position: absolute;
      width: 0.6em;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(180deg);
    }
  }
  .slick-next{
    width: calc(36px + 24 * (100vw - 769px) / 1151);
    height: calc(36px + 24 * (100vw - 769px) / 1151);
    @media only screen and (min-width: 1920px){
      width: 60px;
      height: 60px;
    }
    background-color: #b7a99a;
    color: #ffffff;
    z-index: 1;

    -webkit-touch-callout:none;
    -webkit-tap-highlight-color:rgba(0,0,0,0);
    user-select: none;
    cursor: pointer;
    position: absolute;
    bottom: 50%;
    right: calc(-70px + -80 * (100vw - 769px) / 1151);
    transform: translate(50%, 50%);
    img{
      position: absolute;
      width: 0.6em;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .cidadeTop{
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    font-size: calc(16px + 4 * (100vw - 769px) / 1151);
    @media only screen and (min-width: 1920px){
      font-size: 20px;
    }
    letter-spacing: 0.2em;
    color: #fff;
    background-color: #b7a99a;
    padding: 10px 20px;
  }
  .cidadeBottom{
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    transform: translateY(55%);
    -webkit-text-stroke: 2px #b7a99a;
    color: transparent;
    z-index: -1;
    font-size: calc(90px + 130 * (100vw - 769px) / 1151);
    @media only screen and (min-width: 1920px){
      font-size: 220px;
    }
    letter-spacing: 0.2em;
  }
  .cidadeOliazemeis{
    letter-spacing: 0.15em;
  }
`