import React, { useState } from "react"
import { CSSTransition } from "react-transition-group"

import { Button } from "../buttons"
import font from "../../fonts/fonts.module.scss"
import styled from "styled-components"

import Intro from "../../components/intro/about"
import Banner from "../../components/banner/about"
import BannerPat from "../../components/banner/aboutPattern"
import BannerManager from "../../components/banner/aboutManager"
import TeamBanner from "../../components/banner/teamBanner"
import TeamList from "../../components/list/team"
import ClinicasSlider from "../../components/sliders/clinicasSlider"

const Sobre = ({ data }) => {
  const [choice, setChoice] = useState(2)

  return (
    <StyledSobre>
      <CSSTransition
        in={choice === 0}
        timeout={350}
        classNames={"switch"}
        unmountOnExit
      >
        <Intro data={data.introE} />
      </CSSTransition>
      <CSSTransition
        in={choice === 1}
        timeout={350}
        classNames={"switch"}
        unmountOnExit
      >
        <Intro data={data.introCorpo} />
      </CSSTransition>
      <CSSTransition
        in={choice === 2}
        timeout={350}
        classNames={"switch"}
        unmountOnExit
      >
        <Intro data={data.introC} />
      </CSSTransition>
      <div className="selection">
        <Button
          styleless
          className={
            choice === 2 ? font.rSB + " selec active" : font.rSB + " selec"
          }
          onClick={() => setChoice(2)}
        >
          CLÍNICAS
        </Button>
        <Button
          styleless
          className={
            choice === 1 ? font.rSB + " selec active" : font.rSB + " selec"
          }
          onClick={() => setChoice(1)}
        >
          CORPO CLÍNICO
        </Button>
        <Button
          styleless
          className={
            choice === 0 ? font.rSB + " selec active" : font.rSB + " selec"
          }
          onClick={() => setChoice(0)}
        >
          <a href="#adriano">
            DIREÇÃO CLÍNICA
          </a>
        </Button>
      </div>
      <div className="container">
        <CSSTransition
          in={choice === 0}
          timeout={350}
          classNames={"switch"}
          unmountOnExit
        >
          <div className="container-content" id="adriano">
            <Banner data={data.banner} />
            {/*<BannerPat data={data.bannerPat} />*/}
            {/*<BannerManager data={data.bannerManager} />*/}
          </div>
        </CSSTransition>
        <CSSTransition
          in={choice === 1}
          timeout={350}
          classNames={"switch"}
          unmountOnExit
        >
          <div className="container-content">
            <TeamBanner data={data.equipa.banner} />
            <TeamList data={data.equipa.team} />
          </div>
        </CSSTransition>
        <CSSTransition
          in={choice === 2}
          timeout={350}
          classNames={"switch"}
          unmountOnExit
        >
          <div className="container-content">
            <ClinicasSlider data={data.clinicasSlider.porto} city="PORTO" />
            <ClinicasSlider data={data.clinicasSlider.aveiro} city="AVEIRO" />
            <ClinicasSlider data={data.clinicasSlider.viseu} city="VISEU" />
            <ClinicasSlider data={data.clinicasSlider.agueda} city="ÁGUEDA" />
            <ClinicasSlider data={data.clinicasSlider.oliazemeis} oliazemeis={true} city="AZEMÉIS" />
          </div>
        </CSSTransition>
      </div>
    </StyledSobre>
  )
}

export default Sobre

const StyledSobre = styled.div`
  position: relative;
  margin-bottom: 100px;
  .selection {
    display: flex;
    justify-content: center;
    margin-bottom: 5em;
    .selec {
      font-size: 16px;
      letter-spacing: 0.1em;
      position: relative;
      margin: 0 40px;
    }
    .active::after {
      content: "";
      background-color: #b7a99a;
      width: 25px;
      height: 3px;
      position: absolute;
      top: 2em;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .container {
    position: relative;
    .container-content {
      position: relative;
    }
  }

  .switch-enter {
    position: relative;
    opacity: 0 !important;
  }
  .switch-enter-active {
    position: relative;
    opacity: 1 !important;
    transition: all 350ms ease-out;
  }
  .switch-exit {
    position: absolute;
    top: 0 !important;
    opacity: 1 !important;
  }
  .switch-exit-active {
    position: absolute;
    top: 0 !important;
    opacity: 0 !important;
    transition: all 350ms ease-out;
  }
`
