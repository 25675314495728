import React from "react"
import Img from "gatsby-image"

import styled from "styled-components"
import font from "../../fonts/fonts.module.scss"

const Member = ({ data }) => {
  return (
    <StyledMember>
      <div className="flip-card">
        <div className="face">
          <Img fluid={data.img.childImageSharp.fluid} style={{ width: `100%`, height: "100%" }} imgStyle={{ objectFit: "contain" }} />
          <div className="content">
            <div className={font.rSB + " title"}>
              <p className="nome">{data.nome}</p>
              <p className="titulo">{data.title}</p>
              <span className="smallUnderline" />
            </div>
          </div>
        </div>
        <div className="back">
          <span className="smallUnderline" />
          <div className="back-content">
            <div className={font.rSB + " title"}>
              <p className="nome">{data.nome}</p>
              <p className="titulo">{data.title}</p>
              <p className="titulo">{data.omd}</p>
            </div>
            {data.specialties.map((data, i) => (
              <p className={font.rM + " especialidades"} key={"tratamentoDetalhe" + i}>{data}</p>
            ))}
          </div>
        </div>
      </div>
    </StyledMember>
  )
}

export default Member

const StyledMember = styled.div`
  position: relative;
  background-color: transparent;
  perspective: 1000px;
  height: fit-content;
  .flip-card{
    position: relative;
    width: 100%;
    height: fit-content;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(183, 169, 154, 0.2);
  }
  :hover .flip-card{
    transform: rotateY(180deg);
  }
  .face, .back{
    position: absolute;
    width: 100%;
    height: fit-content;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform: rotateY(0deg)
  }
  .face{
    position: relative;
    background-color: #b7a99a;
  }
  .back{
    background-color: #fff;
    transform: rotateY(180deg);
    top: 0;
    height: 100%;
    .smallUnderline{
      width: 2em;
      height: 0.25em;
      background-color: black;
      position: absolute;
      bottom: calc(7% + 20px);
      left: calc(20px + 10%);
      z-index: 2;
    }
  }
  .content{
    position: absolute;
    background: rgba(255, 255, 255, 0.9);
    bottom: 7%;
    width: 80%;
    left: 50%;
    transform: translateX(-50%);
    padding: 20px;
    .title{
      position: relative;
      margin-bottom: 0.8em;
      .nome{
        color: #231f20;
        font-size: calc(12px + 4 * (100vw - 769px) / 1151);
        @media only screen and (min-width: 1920px){
          font-size: 16px;
        }
        @media only screen and (max-width: 768px){
          font-size: calc(14px + 5 * (100vw - 320px) / 448);
        }
        text-transform: uppercase;
        letter-spacing: 0.1em;
        margin-bottom: 0.2em;
      }
      .titulo{
        color: #b7a99a;
        font-size: calc(10px + 3 * (100vw - 769px) / 1151);
        @media only screen and (min-width: 1920px){
          font-size: 13px;
        }
        @media only screen and (max-width: 768px){
          font-size: calc(12px + 2 * (100vw - 320px) / 448);
        }
        letter-spacing: 0.1em;
      }
      .smallUnderline{
        width: 2em;
        height: 0.25em;
        background-color: #b7a99a;
        position: absolute;
        bottom: -0.8em;
        left: 0;
      }
    }
  }
  .back-content{
    position: absolute;
    background-color: rgba(183, 169, 154, 1);
    height: 100%;
    width: 100%;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: calc(10% + 20px);
    .title{
      position: relative;
      margin-bottom: 2em;
      @media only screen and (max-width: 1200px){
        margin-bottom: 1em;
      }
      .nome{
        color: #fff;
        font-size: calc(12px + 4 * (100vw - 769px) / 1151);
        @media only screen and (min-width: 1920px){
          font-size: 16px;
        }
        @media only screen and (max-width: 768px){
          font-size: calc(14px + 5 * (100vw - 320px) / 448);
        }
        text-transform: uppercase;
        letter-spacing: 0.1em;
        margin-bottom: 0.2em;
      }
      .titulo{
        color: black;
        font-size: calc(10px + 3 * (100vw - 769px) / 1151);
        @media only screen and (min-width: 1920px){
          font-size: 13px;
        }
        @media only screen and (max-width: 768px){
          font-size: calc(12px + 2 * (100vw - 320px) / 448);
        }
        letter-spacing: 0.1em;
      }
    }
    .especialidades{
      color: #fff;
      font-size: calc(12px + 4 * (100vw - 769px) / 1151);
      @media only screen and (min-width: 1920px){
        font-size: 16px;
      }
      @media only screen and (max-width: 768px){
        font-size: calc(14px + 5 * (100vw - 320px) / 448);
      }
      letter-spacing: 0.1em;
      line-height: 1.6em;
      margin: 0.6em 0;
    }
  }
`