import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import font from "../../fonts/fonts.module.scss"

const Banner = ({ data }) => {

  return (
    <StyledBanner>
      {data.map((itemDoctor, index) => (
        <div key={index} className={`bannerContent ${index % 2 === 0 ? "even" : "odd"}`}>
          {index % 2 === 0 ? (
            <>
              <div className="bannerImg">
                <Img className="img" fluid={itemDoctor.img.childImageSharp.fluid} imgStyle={{ objectFit: "contain" }} />
              </div>
              <div className="sobre-banner-text">
                <div className="div-title">
                  <span className={font.rB + ` sobre-BTitle ${index % 2 === 0 ? "even" : "odd"}`}>{itemDoctor.title}</span><br />
                </div>
                <div className="div-sub">
                  <span className={font.rMI + ` sobre-BSub ${index % 2 === 0 ? "even" : "odd"}`}>{itemDoctor.subtitle}</span><br /><br /><br />
                </div>
                <p className={font.rM + ` sobre-BContent ${index % 2 === 0 ? "even" : "odd"}`} dangerouslySetInnerHTML={{ __html: itemDoctor.text }} />
              </div>
            </>
          ) : (
            <>
              <div className="sobre-banner-text">
                <div className="div-title">
                  <span className={font.rB + ` sobre-BTitle ${index % 2 === 0 ? "even" : "odd"}`}>{itemDoctor.title}</span><br />
                </div>
                <div className="div-sub">
                  <span className={font.rMI + ` sobre-BSub ${index % 2 === 0 ? "even" : "odd"}`}>{itemDoctor.subtitle}</span><br /><br /><br />
                </div>
                <p className={font.rM + ` sobre-BContent ${index % 2 === 0 ? "even" : "odd"}`} dangerouslySetInnerHTML={{ __html: itemDoctor.text }} />
              </div>
              <div className="bannerImg">
                <Img className="img" fluid={itemDoctor.img.childImageSharp.fluid} imgStyle={{ objectFit: "contain" }} />
              </div>
            </>
          )}
        </div>
      ))}
    </StyledBanner>
  )
}

export default Banner

const StyledBanner = styled.div`
  .even {
    background-color: black;
    color: #baad9f;
  }
  
  .odd {
    background-color: #baad9f;
    color: #000;
  }

  .bannerContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5vw;

    @media only screen and (min-width: 1920px) {
      padding: 90px;
    }

    .bannerImg {
      height: 100%;
      width: 30%;

      .img {
        width: 100%;
        max-height: 100%;
      }
    }

    .sobre-banner-text {
      width: 55%;
      font-size: 0.85em;

      @media only screen and (min-width: 1920px) {
        font-size: 22px;
      }

      .div-title {
        .sobre-BTitle {
          font-size: 41px;
          letter-spacing: 0.15em;

        }
        .even {
          color: #baad9f;
        }
        
        .odd {
          color: #000;
        }
      }

      .div-sub {
        .even {
          color: #fff;
        }
        
        .odd {
          color: #000;
        }

        .sobre-BSub {
          font-size: 22px;
          letter-spacing: 0.15em;
        }
      }

      .sobre-BContent {
        font-size: 18px;
        letter-spacing: 0.1em;
        line-height: 1.6em;
        color: #ffffff;
      }

      .even {
        color: #fff;
      }
      
      .odd {
        color: #000;
      }
    }
  }
`;
