import React from "react"
import Img from "gatsby-image"

import styled from "styled-components"
import font from "../../fonts/fonts.module.scss"

const BannerManager = ({ data }) => {
  
  return(
    <StyledBanner>
      <div className="contentImg">
        <Img fluid={data.img.childImageSharp.fluid} style={{ width: `100%`, height: "100%" }} imgStyle={{ objectFit: "contain" }}/>
      </div>
      <p className={font.rB + " title"}>VASCO AFONSO</p>
      <p className={font.rMI + " subtitle"}>General Manager</p>
      <p className={font.rM + " text"}>
        Vasco Afonso possui um percurso de 14 anos na área da medicina dentária.<br/><br/> 
        Em Portugal, desempenhou o cargo de supervisor e de diretor nacional no Straumann Group - líder mundial em implantologia. Nos últimos anos, foi diretor comercial do mesmo grupo em Espanha, no qual dirigiu marcas como Neodent e Medentika.<br/><br/>
        Atualmente é o general manager da marca Carreira Dental Clinic.
      </p>
    </StyledBanner>
  )
}

export default BannerManager

const StyledBanner = styled.div`
overflow: hidden;
margin-top: 60px;
  .contentImg{
    width: 80%;
    min-width: 240px;
    margin: auto;
  }
  .title{
    font-size: calc(20px + 12 * (100vw - 320px) / 448);
    @media only screen and (min-width: 769px){
      font-size: 32px;
    }
    line-height: 1.6em;
    color: #b7a99a;
    text-align: center;
    margin: 20px 40px 0;
  }
  .subtitle{
    font-size: calc(18px + 8 * (100vw - 320px) / 448);
    @media only screen and (min-width: 769px){
      font-size: 26px;
    }
    line-height: 1.1em;
    color: #231f20;
    text-align: center;
    margin: 0 40px 30px;
  }
  .text{
    font-size: calc(14px + 6 * (100vw - 320px) / 448);
    @media only screen and (min-width: 769px){
      font-size: 20px;
    }
    line-height: 1.6em;
    letter-spacing: 0.1em;
    color: #808080;
    margin: 40px;
  }
`